var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.darkmode ? 'darkmodebg' : 'lightmodebg',
    _vm.$vuetify.breakpoint.mobile ? 'full-width' : '',
    _vm.mini && !_vm.$vuetify.breakpoint.mobile ? 'app-bar-full' : 'app-bar' ],staticStyle:{"position":"fixed","top":"0","z-index":"6"}},[_c('v-app-bar',{staticClass:"bg-transparent",attrs:{"fluid":"","elevation":"0","elevate-on-scroll":"","scroll-target":"#scroll-reference"}},[_c('v-row',{staticClass:"d-flex pr-o pr-sm-8"},[_c('v-col',{staticClass:"d-flex align-center px-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{staticClass:"font-weight-600 text-capitalize drawer-toggler py-3 rounded-sm",class:{
            'btn-dark-toggler-hover': !_vm.hasBg,
            'btn-toggler-hover': _vm.hasBg,
            active: _vm.togglerActive,
          },style:({
            minWidth: _vm.$vuetify.breakpoint.mobile ? '32px' : 'auto',
          }),attrs:{"elevation":"0","ripple":false,"height":"43","color":"transparent"},on:{"click":_vm.drawerClose}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line",class:[_vm.darkmode ? 'bg-white' : 'bg-black']}),_c('i',{staticClass:"drawer-toggler-line",class:[_vm.darkmode ? 'bg-white' : 'bg-black']}),_c('i',{staticClass:"drawer-toggler-line",class:[_vm.darkmode ? 'bg-white' : 'bg-black']})])]):_vm._e(),_c('HorizontalForm',{staticStyle:{"width":"100%"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-wrap"},[_c('SponsorsFooter'),[_c('v-footer',{staticClass:"px-6 mt-16",attrs:{"color":"transparent"}},[_c('v-card',{staticClass:"flex",attrs:{"color":"transparent"}},[_c('v-card-text',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center justify-md-start align-center",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"copyright text-body-2 ls-0",style:({ color: _vm.darkmode ? 'white' : '#232228' })},[_c('span',{staticClass:"font-weight-regular text-h3 d-flex align-end"},[_c('span',{staticClass:"mr-2 text-subtitle-2 font-weight-bold"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" ")]),_c('div',{style:({ color: _vm.darkmode ? 'white' : '#15121D' })}),_c('v-img',{staticClass:"my-auto",attrs:{"max-height":"12px","max-width":"12px","src":require("@/assets/optical.svg")}}),_c('span',{staticClass:"font-weight-bold indigo--text text--lighten-1",staticStyle:{"letter-spacing":"-2px !important"}},[_vm._v(" ptical ")])],1)])]),_c('v-col',{staticClass:"d-flex justify-center justify-md-end",attrs:{"cols":"12","md":"6"}},_vm._l((_vm.footer),function(item,index){return _c('ul',{key:item.linkName,staticClass:"d-flex list-style-none"},[_c('li',[(index !== 2)?_c('a',{staticClass:"text-decoration-none text-subtitle-2 font-weight-bold ls-0",class:{
                      'btn-dark-hover no-default-hover': _vm.darkmode,
                    },style:({ color: _vm.darkmode ? 'white' : '#232228' }),attrs:{"href":item.link,"target":"_blank"}},[(item.linkName)?_c('span',[_vm._v(" "+_vm._s(item.linkName)+" ")]):_vm._e(),(item.linkIcon)?_c('v-icon',{attrs:{"size":"20","color":_vm.darkmode ? 'white' : '#232228'}},[_vm._v(" "+_vm._s(item.linkIcon)+" ")]):_vm._e()],1):_c('span',{staticClass:"text-decoration-none text-subtitle-2 font-weight-bold ls-0",class:{
                      'btn-dark-hover no-default-hover': _vm.darkmode,
                    },staticStyle:{"cursor":"pointer"},style:({ color: _vm.darkmode ? 'white' : '#232228' }),on:{"click":function($event){_vm.donateDialog = true}}},[(item.linkName)?_c('span',[_vm._v(" "+_vm._s(item.linkName)+" ")]):_vm._e(),(item.linkIcon)?_c('v-icon',{attrs:{"size":"20","color":_vm.darkmode ? 'white' : '#232228'}},[_vm._v(" "+_vm._s(item.linkIcon)+" ")]):_vm._e()],1)])])}),0)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"400","overlay-color":_vm.darkmode ? 'grey' : 'rgb(33, 33, 33)',"overlay-opacity":_vm.darkmode ? 0.2 : 0.46},model:{value:(_vm.donateDialog),callback:function ($$v) {_vm.donateDialog=$$v},expression:"donateDialog"}},[_c('v-card',{attrs:{"dark":_vm.darkmode}},[_c('v-card-title',{staticClass:"text-h4 font-weight-bold flex justify-center lighten-2"},[_vm._v(" Donate ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-6 px-6"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"12"}},[_c('v-select',{staticClass:"d-flex align-center custom-scrollbar",attrs:{"items":_vm.donationAddresses,"item-text":"network","item-value":"value","return-object":"","menu-props":_vm.darkmode ? 'dark' : 'light',"dense":"","flat":"","color":"white","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"font-weight-bold text-subtitle-2"},[_vm._v(" "+_vm._s(item.network)+" ")])])]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"font-weight-bold text-subtitle-2"},[_vm._v(" "+_vm._s(item.network)+" ")])])]}}]),model:{value:(_vm.selectedWallet),callback:function ($$v) {_vm.selectedWallet=$$v},expression:"selectedWallet"}}),(_vm.selectedWallet)?_c('vue-qr',{staticClass:"my-6",attrs:{"text":_vm.selectedWallet.value,"margin":5,"size":150}}):_vm._e(),(_vm.selectedWallet)?_c('v-text-field',{staticClass:"font-size-input placeholder-dark input-alternative input-icon",staticStyle:{"width":"100%"},attrs:{"value":_vm.selectedWallet.value,"rounded":"","outlined":"","readonly":"","dark":_vm.darkmode,"label":_vm.selectedWallet.network + ' Address'},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"indigo lighten-1"},on:{"click":function($event){return _vm.copyToClipboard()}}},[_vm._v(" mdi-clipboard ")])]},proxy:true}],null,false,2975220766)}):_vm._e(),(_vm.copied)?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Address copied to clipboard! ")]):_vm._e()],1)],1)],1),_c('v-divider')],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
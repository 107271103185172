var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"py-1",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"px-4 px-md-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"flex-nowrap d-flex"},[_c('v-col',{class:_vm.fieldActive ? 'flex-shrink-0' : 'flex-shrink-1',attrs:{"md":"8","lg":"6","cols":_vm.fieldActive ? '12' : '12'}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"font-size-input input-alternative input-icon mr-2 custom-placeholder-color search-bar search-input",style:({
                backgroundColor: _vm.darkmode ? '#232228' : 'white',
              }),attrs:{"disabled":_vm.loading,"rounded":"","outlined":"","flat":"","color":"white","rules":_vm.walletRules,"dark":_vm.darkmode,"hide-details":"","placeholder":"Enter wallet address..."},on:{"focus":function($event){_vm.fieldActive = true},"blur":function($event){_vm.fieldActive = false}},scopedSlots:_vm._u([(
                  (!_vm.wallet ||
                    !_vm.connectedWallet ||
                    _vm.wallet !== _vm.connectedWallet) &&
                  (_vm.$vuetify.breakpoint.smAndUp || _vm.fieldActive)
                )?{key:"append",fn:function(){return [(_vm.wallet || _vm.walletType)?_c('span',{staticClass:"caption pl-2 font-weight-bold text-uppercase mt-n1",class:[_vm.walletType ? 'blue--text' : 'red--text']},[(Boolean(_vm.walletType))?_c('span',[_vm._v(" "+_vm._s(_vm.walletType)+" ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert-outline ")])],1):_vm._e()]},proxy:true}:(
                  _vm.connectedWallet &&
                  (_vm.$vuetify.breakpoint.smAndUp || _vm.fieldActive)
                )?{key:"append",fn:function(){return [(_vm.wallet || _vm.walletType)?_c('span',{staticClass:"caption pr-4 pl-2 font-weight-bold text-uppercase",class:[_vm.walletType ? 'blue--text' : 'red--text']},[(Boolean(_vm.walletType))?_c('span',[_vm._v(" "+_vm._s(_vm.walletType)+" ")]):_c('v-icon',[_vm._v(" mdi-alert-outline ")])],1):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.wallet),callback:function ($$v) {_vm.wallet=$$v},expression:"wallet"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"size":".875rem"}},[_vm._v("fas fa-search")])],1)],2),_c('div',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"font-weight-600 text-capitalize rounded-circle grow pa-0",attrs:{"disabled":_vm.loading || !_vm.walletType,"ripple":false,"height":"25","min-width":"25","depressed":"","width":"25","color":"indigo lighten-1","dark":_vm.darkmode},on:{"click":function($event){return _vm.loadPortfolio({ walletAddress: _vm.wallet, walletType: _vm.walletType })}}},[_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v(" fa-arrow-right ")])],1)],1)],1)]),(_vm.$vuetify.breakpoint.smAndUp || !_vm.fieldActive)?_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.darkmode ? 'white' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 rounded-circle px-0 elevation-0",staticStyle:{"height":"40px","min-width":"40px"},style:({
                  backgroundColor: _vm.darkmode ? '#232228' : 'white',
                }),attrs:{"large":""},on:{"click":function($event){return _vm.setWalletDialog(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":_vm.darkmode ? 'indigo lighten-1' : '#232228'}},[_vm._v(" mdi-connection ")])],1)]}}],null,false,454983850)},[_c('span',{staticClass:"text-caption font-weight-bold",class:_vm.darkmode ? 'indigo--text text--lighten-2' : ''},[_vm._v(" Connect your wallet ")])]),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","elevation":"0","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 rounded-circle px-0 elevation-0",staticStyle:{"height":"40px","min-width":"40px"},style:({
                  backgroundColor: _vm.darkmode ? '#232228' : 'white',
                }),attrs:{"large":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":_vm.darkmode ? 'white' : '#232228'}},[_vm._v(" mdi-cog-outline ")])],1)]}}],null,false,4176531598)},[_c('div',{staticClass:"mt-2 rounded-lg",staticStyle:{"max-width":"230px"},style:({
                backgroundColor: _vm.darkmode ? '#0C0B10' : 'white',
              })},[_c('v-list',{staticClass:"px-2",staticStyle:{"background":"transparent"},attrs:{"disabled":_vm.loading,"dense":""}},_vm._l((_vm.settingsOptions),function(settingsOption,key){return _c('v-list-item',{key:key,staticClass:"d-flex py-1 my-1 rounded-lg",class:settingsOption.showCondition ? 'grey darken-4' : '',attrs:{"dense":""},on:{"click":settingsOption.actionMethod}},[_c('v-list-item-icon',{staticClass:"d-flex align-self-start mr-6"},[(settingsOption.showCondition)?_c('v-icon',{class:[
                        !_vm.darkmode ? 'text--darken-4' : 'text--darken-1',
                        key === 1
                          ? 'yellow--text'
                          : _vm.darkmode
                          ? 'grey--text'
                          : 'white--text' ],attrs:{"size":"25"}},[_vm._v(" "+_vm._s(settingsOption.onIcon)+" ")]):_c('v-icon',{class:[
                        !_vm.darkmode ? 'text--darken-4' : 'text--darken-1',
                        key === 1 ? 'yellow--text' : 'grey--text' ],attrs:{"size":"25"}},[_vm._v(" "+_vm._s(settingsOption.offIcon)+" ")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold text-caption text-truncate mr-5 d-flex flex-column justify-end",style:({
                      color: _vm.darkmode ? '#f3f4fd' : '',
                    })},[_c('span',[_vm._v(" "+_vm._s(settingsOption.text)+" ")]),(settingsOption.description)?_c('span',{staticClass:"grey--text text-pre-wrap",staticStyle:{"font-size":"10px","margin-top":"5px"}},[_vm._v(_vm._s(settingsOption.description))]):_vm._e()])],1)}),1)],1)]),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","elevation":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-circle px-0 elevation-0",staticStyle:{"height":"40px","min-width":"40px"},style:({
                  backgroundColor: _vm.darkmode ? '#232228' : 'white',
                }),attrs:{"large":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":_vm.darkmode,"color":_vm.darkmode ? 'white' : '#232228',"size":"25"}},[_vm._v(" mdi-account-outline ")])],1)]}}],null,false,8535698)},[_c('div',{staticClass:"mt-2 rounded-lg",staticStyle:{"max-width":"230px","min-width":"200px"},style:({
                backgroundColor: _vm.darkmode ? '#0C0B10' : 'white',
              })},[_c('v-list',{staticStyle:{"background":"transparent"},attrs:{"disabled":_vm.loading,"dense":""}},[(_vm.selectedProfile)?_c('v-list-item',{staticClass:"text-h5 text-uppercase font-weight-bold pt-2 pb-4",style:({
                    borderBottom: _vm.darkmode
                      ? '1px solid grey'
                      : '1px solid #EEE',
                  })},[_c('span',{staticClass:"d-flex flex-column align-center justify-center mx-auto text-truncate",staticStyle:{"letter-spacing":"0px"},style:({
                      color: _vm.darkmode ? '#f3f4fd' : '#0C0B10',
                    })},[_c('v-icon',{attrs:{"size":"50","color":_vm.darkmode ? 'indigo lighten-1' : 'grey'}},[_vm._v(" mdi-account-circle-outline ")]),_vm._v(" "+_vm._s(_vm.selectedProfile.name)+" ")],1)]):_vm._e(),_c('v-list-item',{staticClass:"text-h4 text-uppercase font-weight-bold"},[_c('span',{staticStyle:{"letter-spacing":"0px"},style:({
                      color: _vm.darkmode ? '#f3f4fd' : '#0C0B10',
                    })},[_vm._v(" Select profile ")])]),_c('div',{staticClass:"custom-scrollbar",staticStyle:{"max-height":"180px","overflow-y":"auto"}},_vm._l((_vm.userProfiles.filter(function (profile) {
                      if (_vm.selectedProfile) {
                        return profile.name !== _vm.selectedProfile.name;
                      }

                      return true;
                    })),function(profile,i){return _c('v-list-item',{key:i,attrs:{"dense":""},on:{"click":function($event){return _vm.loadProfile({ profile: profile })}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"size":"20","color":_vm.darkmode ? 'grey' : '#232228'}},[_vm._v(" mdi-account-circle-outline ")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold text-caption text-truncate",style:({
                        color: _vm.darkmode ? '#f3f4fd' : '',
                      })},[_vm._v(" "+_vm._s(profile.name)+" ")])],1)}),1)],1)],1)])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }